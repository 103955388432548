import { Out } from "@/infrastructure/frontent/clientMessage";

export enum AppConst {
  NotApplicable = "Diese Funtkion darf nicht benutz werden",
  MinDate = "2000-01-01"
}

export function getApplicationConstText(appConst: AppConst): string {
  return appConst.toString();
}

export const StartVersion = "2.01"
export const CodeVersion = "2.01"
export const MinDbVersion = 11
export const disableButtonTime = 1200
//export const disableSaveButtonTime = 6000

export class AppStateContext {
  private static isSaveButtoEnabled = true
  private static storedElement: any = {}

  public static isSaveButtonEnabled() {
    return AppStateContext.isSaveButtoEnabled
  }

  // public static disableSaveButtonX(element: any) {
  //   AppStateContext.isSaveButtoEnabled = false
  //   try {
  //     AppStateContext.storedElement = element
  //     AppStateContext.storedElement.value = false
  //   } catch (error) {
  //     Out.noOperation()
  //   }
  // }

  // public static enableSaveButtonX() {
  //   AppStateContext.isSaveButtoEnabled = true
  //   try {
  //     AppStateContext.storedElement.value = true
  //   } catch (error) {
  //     Out.noOperation()
  //   }
  // }

}