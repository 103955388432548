
  import { reactive} from "vue";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  
  export default {
    name: "FishBook",
    inheritAttrs: false,
    components: {
      DynamicForm
    },
    setup() {
      const request = reactive({
        apiLoadMethod: "api/Form/loadForm",
        apiSaveMethod: "",
        form: "HomeFischerei",
        page: "HomeFischereiDaten",
        remoteId: "",
        waitForResponse: true,
        geoEditMode: "ReadOnly",
        geoGpsMode: "OnStartup",
        geoDisplayClass: "fishing",
        geoCheckInsidePolygon: false,
        geoCenterVisible: false,
        scrollPageClass: "scrollPageComplete"

      });
      return {
        request
      };
    }
  };
  